import React from 'react'
// https://launch.doctorevidence.com/images/DREBackground.jpeg
import { Button } from '@mui/material'
import { useAuth } from '../hooks/useAuth';
import { Box, Link } from '@mui/material'

export const Homepage = () => {

  const {onLogout, handleLogout2} = useAuth();

  return (
    <div>


  <div>
    <h1>DRE App Links</h1>
  </div>

  <Box> 
    <ul>
      <li> <Link href="search-report"> search-report </Link>: Create a table of best match trial population features from DOCsearch annotations.
      </li>
      <li> <Link href="trial-lookup"> trial-lookup </Link>: Lookup a clinical trial given some basic trial features.
      </li>
      <li> <Link href="trial-landscape"> trial-landscape </Link>: Quick landscape and pptx of clinical trials.
      </li> 
      <li> <Link href="https://searchdev.doctorevidence.com/regintel/"> regintel </Link>: Regulatory intelligence
      </li> 
  </ul>
  </Box>

  <Button onClick={onLogout}>
    LOGOUT
  </Button>



</div>  
  )
}
