import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

import App from './App';

import {customTheme} from './utils/Theme'
//context
import { ThemeProvider } from '@emotion/react';
import {AuthProvider} from './context/AuthContext'


const root = ReactDOM.createRoot(document.getElementById('root'));




root.render(
  <React.StrictMode>
    <ThemeProvider theme = {customTheme}>    

    <AuthProvider>      
    <App />
    </AuthProvider> 
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

