import { createTheme} from '@mui/material/styles';
import { lightBlue } from '@mui/material/colors'

export const customTheme = createTheme({
    palette: {
      primary: {
        main: "#D046FF",
        white: "#EEEEEE",
        contrastText: 'white',
        DRE_Purple: "#D046FF",
        Deep_Purple: "#7D00DE",
        Med_Purple: "#BE80EF",
        Dim_Purple: "#E6D3F4",
        Deep_Blue: "#000A2D",
        Med_Blue: "#0078FF",
        Light_Blue: "#00ACFF",
        Dim_Blue: "#00ACFF",
        Warm_White: "#EEEEEE",
        Light_White: "#F6F6F6",
        Med_Grey: "#505050",
        Light_Grey: "#9C9C9C",
        Dim_Grey: "#D0D0D0",
      },
      action: {
        active: lightBlue[200],
        activeOpacity: 1,
        hover: lightBlue[100],
        hoverOpacity: 0.7,
        focus: lightBlue[600],
        focusOpacity: 1,
        selected: lightBlue[300],
        selectedOpacity: 1
      },
    },
  });




